<template>
  <div class="content">
        <div class="content-warp">
            <crumbs :crumbs="crumbs" />
        </div>
        <div style="margin-top:40px">
          <el-form>
            <el-row>
                <el-col :span="12">
                  <el-form-item label="商品编号" label-width="100px">
                    <el-input v-model="form.itemName"></el-input>
                  </el-form-item>
                </el-col>
                 <el-col :span="12">
                   <el-form-item label="货架" label-width="100px">
                    <el-input v-model="form.subHouseName"></el-input>
                  </el-form-item>
                 </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                  <el-form-item label="商品图片" label-width="100px">
                    <el-image 
                        style="width: 100px; height: 100px"
                        :src="url" 
                        :preview-src-list="srcList">
                      </el-image>
                    <!-- <el-input v-model="form.itemName"></el-input> -->
                  </el-form-item>
                </el-col>
              </el-row>
               <el-row>
                 <el-form-item label="总库存" label-width="100px">
                   {{form.skuNum}}
                    <!-- <el-input v-model="form.subHouseName"></el-input> -->
                  </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="状态" label-width="100px">
                    <el-select clearable v-model="userType" placeholder="请选择" @change="changetType">
                            <el-option value="onSkuAttrList" label="在库"></el-option>
                            <el-option value="outSkuAttrList" label="出库"></el-option>
                        </el-select>
                  </el-form-item>
              </el-row>
          </el-form>
          
        </div>
         <el-table
              class="table-style"
                :data="tableData"
                header-align="center"
                height="550"
                style="width: 100%">
                <el-table-column
                  align="center"
                  prop="colorName"

                  label="尺码"
                  >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="sizeName"

                  label="颜色"
                  >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="skuNum"

                  label="数量"
                  >
                </el-table-column>
                 <el-table-column
                  align="center"
                  prop="wxNick"

                  label="操作"
                  >
                  <template slot-scope="scope">
                    <div class="updateBtn center" @click="see(scope.row)">
                      查看
                      <!-- 历史 -->
                      <!-- <el-popover
                        placement="right"
                        width="400"
                        trigger="hover">
                        <el-table :data="gridData">
                          <el-table-column width="150" property="date" label="日期"></el-table-column>
                        </el-table> -->
                        <!-- <el-button slot="reference">历史</el-button> -->
                      <!-- </el-popover> -->
                    </div>
                  </template>
                </el-table-column>
          </el-table>
        <!-- <el-row>
            <el-col>

            </el-col>
        </el-row> -->
          <el-dialog :visible.sync="showPop" width="60%" :title="title" :before-close="handleDialogClose" :close-on-click-modal="false">
            <el-table
                class="table-style"
                    :data="tableDataList"
                      v-show="userType == 'onSkuAttrList'"
                    height="550"
                    style="width: 100%">
                    <!-- <el-table-column
                    align="center"
                      prop="date"
                      label="日期"
                      >
                    </el-table-column> -->
                    <el-table-column
                      align="center"
                      prop="sizeName"
                      
                      label="尺码"
                      >
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="colorName"
                      
                      label="颜色"
                      >
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="skuId"
                      
                      label="商品编号"
                      >
                    </el-table-column>
                      <el-table-column
                  align="center"
                  prop="wxNick"

                  label="操作"
                  >
                  <template slot-scope="scope">
                    <div class="updateBtn center" @click="see">
                      <!-- 查看 -->
                      <!-- 历史 -->
                      <el-popover
                        placement="right"
                        width="400"
                        trigger="hover">
                        <el-table :data="scope.row.forTheLibraryList">
                          <el-table-column width="150" property="forTheLibrary" label="操作"></el-table-column>
                           <el-table-column width="150" property="createTime" label="时间"></el-table-column>
                        </el-table>
                        <el-button slot="reference">历史</el-button>
                      </el-popover>
                    </div>
                  </template>
                </el-table-column>
            </el-table>
            <el-table
                class="table-style"
                  v-show="userType != 'onSkuAttrList'"
                    :data="tableDataList"
                    height="550"
                    style="width: 100%">
                    <!-- <el-table-column
                    align="center"
                      prop="date"
                      label="日期"
                      >
                    </el-table-column> -->
                    <el-table-column
                      align="center"
                      prop="sizeName"
                      
                      label="尺码"
                      >
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="colorName"
                      
                      label="颜色"
                      >
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="skuId"
                      
                      label="商品编号"
                      >
                    </el-table-column>
                     <el-table-column
                      align="center"
                      prop="orderId"
                    
                      label="订单编号"
                      >
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="buyerName"
                      label="对接买手"
                      >
                    </el-table-column>
                      <el-table-column
                  align="center"
                  prop="wxNick"

                  label="操作"
                  >
                  <template slot-scope="scope">
                    <div class="updateBtn center" @click="see">
                      <!-- 查看 -->
                      <!-- 历史 -->
                      <el-popover
                        placement="right"
                        width="400"
                        trigger="hover">
                        <el-table :data="scope.row.forTheLibraryList">
                          <el-table-column width="150" property="forTheLibrary" label="操作"></el-table-column>
                           <el-table-column width="150" property="createTime" label="时间"></el-table-column>
                        </el-table>
                        <el-button slot="reference">历史</el-button>
                      </el-popover>
                    </div>
                  </template>
                </el-table-column>
            </el-table>
          </el-dialog>
  </div>
</template>
<script>
import crumbs from '../../components/public/crumbs.vue'
export default {
  components:{
    crumbs
  },
  data(){
    return{
       crumbs: [
            {text:'库存管理',path:'/stockManagement'},
            {text:'库存详情',path:'/stockInfo'},
        ],
        tableDataList:[],
        userType:'onSkuAttrList',
        itemId:'',
        title:'',
        url:'',
        srcList:[],
        tableData:[],
        gridData:[],
        showPop:false,
        form:{}
    }
  },
  created(){
    this.itemId = this.$route.query.id
    this.getData()
  },
  methods:{
    changetType(){
      if(this.userType == 'onSkuAttrList') {
        this.tableData = this.onSkuAttrList
      } else {
        this.tableData = this.outSkuAttrList
      }
    },
    see(value){
       if(this.userType == 'onSkuAttrList') {
        this.title =  '在库库存--查看'
        this.getOnSkuItemInfoList(value)

      } else {
        this.title = '出库库存--查看'
        this.getOutSkuItemInfoList(value)
      }
      // this.showPop = true
    },
    getOnSkuItemInfoList(value){
      console.log(value)
      let params = {
        itemId:value.itemId,
        skuId:value.skuId,
        colorName:value.colorName,
        sizeName:value.sizeName
      }
      this.$post('/skuCodeManage/getOnSkuItemInfoList',params).then(res =>{
        console.log(res)
        this.showPop = true
        let list = res.data.list
        this.tableDataList = res.data.list
        
      })
    },
     getOutSkuItemInfoList(value){
      console.log(value)
      let params = {
        itemId:value.itemId,
        skuId:value.skuId,
        colorName:value.colorName,
        sizeName:value.sizeName
      }
      this.$post('/skuCodeManage/getOutSkuItemInfoList',params).then(res =>{
        console.log(res)
        this.showPop = true
        this.tableDataList = res.data.list
      })
    },
    // skuCodeManage/getOnSkuItemInfoList 
    handleDialogClose(){
      this.showPop = false
    },
    getData(){
      this.$get('/skuCodeManage/searchSkuCodeManageInfoByItemId',{itemId:this.itemId}).then(res =>{
           this.form = res.data
           this.url = res.data.mainImages.split(',')[0]
           console.log(this.url)
           this.srcList = res.data.mainImages.split(',')
            this.tableData = res.data.onSkuAttrList
           this.outSkuAttrList = res.data.outSkuAttrList
            this.onSkuAttrList = res.data.onSkuAttrList
          // 
          
      })
    }
  }
}
</script>
<style scoped lang="scss">
.center{
  width: 100%;
  height: 50px;
  text-align:center
}
</style>